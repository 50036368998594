<script>
import { ValidationObserver } from 'vee-validate'
import { capitalizeFirstLetter } from '/~/utils/format/string'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import InputAction from '/~/components/base/input-action/input-action.v2.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useForm } from '/~/composables/base/use-form'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'

export default {
  name: 'fly-new-account-form',
  components: {
    BaseButton,
    InputAction,
    BaseCheckbox,
    BaseLink,
    ValidationObserver,
  },
  setup() {
    const { isSymbioneliteDesignV2 } = useProvider()
    const {
      createAccount,
      verifyBusinessNumber,
      selectedPartner,
      showTerms,
      termsLabel,
      registrationUrl,
    } = usePointsPrograms()
    const { translate } = useLocalization()
    const { validationObserverRef } = useForm()

    return {
      isSymbioneliteDesignV2,
      selectedPartner,
      createAccount,
      verifyBusinessNumber,
      capitalizeFirstLetter,
      showTerms,
      termsLabel,
      validationObserverRef,
      translate,
      registrationUrl,
    }
  },
  data() {
    return {
      from: '',
      abn: '',
      firstName: '',
      lastName: '',
      processingBusinessNumber: false,
      unmaskedBusinessNumber: '',
      backendErrors: {},
      validatedBusinessNumber: null,
      creating: false,
      agree: false,
    }
  },
  computed: {
    maskFormat() {
      return /^[a-zA-Z\d-]*$/
    },
    canProceed() {
      return this.agree && this.firstName && this.lastName
    },
    partnerType() {
      return this.selectedPartner.type
    },
    accountNumberLabel() {
      return this.capitalizeFirstLetter(
        this.selectedPartner.accountNumberLabel ?? ''
      )
    },
    abnName() {
      return this.firstName === this.lastName
        ? this.firstName
        : `${this.firstName} ${this.lastName}`
    },
  },
  methods: {
    async validateBusinessNumber() {
      this.backendErrors = {}
      this.firstName = ''
      this.lastName = ''
      const businessNumber = this.unmaskedBusinessNumber

      if (!businessNumber || this.processingBusinessNumber) {
        return
      }

      try {
        this.processingBusinessNumber = true
        delete this.backendErrors.abn
        const payload = {
          programType: this.partnerType,
          accountNumber: businessNumber,
        }
        const options = {
          notify: false,
        }
        const { data } = await this.verifyBusinessNumber(payload, options)
        const { firstName, lastName, companyName } = data?.metadata ?? {}

        this.validatedBusinessNumber = businessNumber

        this.firstName = firstName || companyName
        this.lastName = lastName || companyName
      } catch (error) {
        this.backendErrors.abn =
          error.data?.message || error?.message || error.accountNumber?.[0]

        return
      } finally {
        this.processingBusinessNumber = false
      }
    },
    async onSubmit() {
      this.creating = true
      const payload = {
        accountNumber: this.validatedBusinessNumber,
        type: this.partnerType,
        firstName: this.firstName,
        lastName: this.lastName,
      }

      this.createAccount(payload)
        .then((response) => {
          this.creating = false
          this.$emit('success', response)
        })
        .catch((error) => {
          if (!error.data?.message) {
            this.$notify({
              type: 'error',
              text: 'Something went wrong, please try again later',
            })
          }
        })
        .finally(() => {
          this.creating = false
        })
    },
    removeWhiteSpace(text) {
      return text.replace(/[\s/]/g, '')
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ errors, handleSubmit, valid }"
    ref="validationObserverRef"
    slim
  >
    <div class="flex w-full flex-col">
      <input-action
        v-model="abn"
        :validation="{
          rules: `required`,
          name: accountNumberLabel,
        }"
        :error="backendErrors.abn"
        :disabled="processingBusinessNumber"
        :loading="processingBusinessNumber"
        :label="accountNumberLabel"
        entry-class="h-11 rounded"
        :icon-size="24"
        name="abn"
        :mask="{ mask: maskFormat }"
        required
        icon="v2/heroic/plane"
        @click="validateBusinessNumber"
        @unmasked="(value) => (unmaskedBusinessNumber = value)"
        @input="abn = removeWhiteSpace($event)"
      >
        <template #error>
          {{ (errors['abn'] && errors['abn'][0]) || backendErrors.abn }}
          <template
            v-if="registrationUrl && !errors['abn'] && !backendErrors.abn"
          >
            <base-link :href="registrationUrl">Join free today</base-link>
          </template>
        </template>
        <template v-if="abnName" #disclaimer>
          <p class="mt-2.5 text-neutral-600">{{ abnName }}</p>
        </template>
      </input-action>

      <div class="mt-1 flex items-start sm:mt-3">
        <base-checkbox v-model="agree" look="v3" name="agree" />
        <div class="-mt-[3px]">
          <span class="text-default">I agree to</span>
          <span
            class="cursor-pointer pl-1 font-bold text-primary underline-offset-4"
            @click="showTerms"
          >
            <span class="hover:underline">{{ termsLabel }}</span>
          </span>
        </div>
      </div>

      <base-button
        :loading="creating"
        :disabled="!canProceed || !valid"
        class="mt-6 sm:mt-8"
        full-width
        @click="handleSubmit(onSubmit)"
      >
        Add
      </base-button>
    </div>
  </validation-observer>
</template>

<script>
import get from 'lodash-es/get'
import { capitalize } from '/~/utils/format/string'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import NewAccountForm from '../../components/new-account-form.vue'

export default {
  name: 'fly-partner-add-new-desktop',
  components: {
    NewAccountForm,
    BaseImage,
    BaseBack,
    BaseLink,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      capitalize,
    }
  },
  computed: {
    icon() {
      return get(this.partner, 'icon', '')
    },
    name() {
      return get(this.partner, 'name', '')
    },
  },
}
</script>

<template>
  <div>
    <base-back class="mr-auto" />
    <div class="mx-auto flex max-w-[640px] flex-col items-center px-8">
      <div class="my-8 h-14 text-orange-700">
        <base-image :src="icon" class="h-full" :ratio="false" />
      </div>

      <h3 class="text-center text-2xl font-bold text-eonx-neutral-800">
        {{ capitalize(name) }}
      </h3>

      <div
        class="mt-8 space-y-4 leading-6 text-eonx-neutral-600"
        v-html="partner.addNewDescription"
      />

      <new-account-form
        class="mx-auto mt-8 w-full"
        @success="$emit('success', $event)"
      />

      <p
        v-if="partner.registrationUrl"
        class="my-8 text-center leading-7 text-eonx-neutral-600"
      >
        Not a {{ name }} member?
        <br />
        <base-link
          :href="partner.registrationUrl"
          class="mr-1 inline-block font-bold text-primary underline-offset-4 hover:underline"
        >
          Join free today
        </base-link>

        (Saving $89.50)
      </p>
    </div>
  </div>
</template>

<script>
import get from 'lodash-es/get'
import ui from '/~/core/ui'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'
import PartnerAddDesktop from './partner-add-new.desk.vue'
import PartnerAddMobile from './partner-add-new.mobile.vue'

export default {
  name: 'fly-partner-add-new',
  components: {
    PartnerAddDesktop,
    PartnerAddMobile,
  },
  setup() {
    const { partners, selectedPartner, findPartner } = usePointsPrograms()

    return {
      partners,
      selectedPartner,
      findPartner,
      ui,
    }
  },
  computed: {
    component() {
      return !ui.mobile ? PartnerAddDesktop : PartnerAddMobile
    },
    paramData() {
      return get(this.$route, 'params')
    },
    partnerType() {
      return get(this.$route, 'query.type')
    },
  },
  created() {
    if (Object.keys(this.selectedPartner).length === 0) {
      this.selectedPartner = this.findPartner(this.partnerType)
    }
  },
  methods: {
    onSuccess(account) {
      this.$router.push({
        name: 'fly-points-transfer',
        params: { account: account },
        query: { type: this.$route.query.type, accountId: account.id },
      })
    },
  },
}
</script>

<template>
  <div class="flex h-full flex-col">
    <component
      :is="component"
      :partner="selectedPartner"
      @success="onSuccess"
    />
  </div>
</template>
